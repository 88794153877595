import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { FontFamily, FontStyle } from '../../models/fontFamily.model';
import { MergeMoveMenuComponent } from '../merge-move-menu/merge-move-menu.component';
import { getDefaultStyle } from '../fonts.utils';
import { LazyLoaderComponent } from '../font-style/lazy-loader.component';
import { FontService } from 'src/services';
import { FontPreviewComponent } from '../font-preview/font-preview.component';

export type FontFamilyWithSource = FontFamily & { source: 'upload' | 'googleFonts' | 'mixed' };

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        MergeMoveMenuComponent,
        LazyLoaderComponent,
        FontPreviewComponent
    ],
    selector: 'font-family-item',
    templateUrl: './font-family-item.component.html',
    styleUrls: ['./font-family-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontFamilyItemComponent {
    private fontService = inject(FontService);

    customText = input.required<string>();
    fontFamily = input.required<FontFamilyWithSource>();
    displayType = input<string>('compact');

    confirmMergeFontFamily = output<{ fontFamily: FontFamily; mergeTo: FontFamily }>();
    openSettings = output<FontFamily>();
    fontFamilies: FontFamilyWithSource[];
    isAllowedToModifyFont: (fontFamily: FontFamily) => boolean;

    constructor() {
        this.isAllowedToModifyFont = this.fontService.isAllowedToModifyFont;
    }

    getFontFamilyStyles(fontFamily: FontFamily): FontStyle {
        return getDefaultStyle(fontFamily.fontStyles);
    }
    getFontFamilyName(fontFamily: FontFamily): string {
        const defaultStyle = getDefaultStyle(fontFamily.fontStyles);
        return `"${this.fontService.getFontStyleName(fontFamily, defaultStyle)}", "AdobeBlank"`;
    }

    settings(): void {
        this.openSettings.emit(this.fontFamily());
    }

    mergeFontFamily(mergeTo: FontFamily): void {
        const fontFamily = this.fontFamily();
        this.confirmMergeFontFamily.emit({
            fontFamily: fontFamily,
            mergeTo
        });
    }
}
