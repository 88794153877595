import { CommonModule } from '@angular/common';
import { Component, inject, input, OnInit, output } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { AuthService } from 'src/services/auth.service';
import { PreventClickDirective } from '../../directive/prevent-click.directive';
import { FontFamily } from '../../models/fontFamily.model';
import { MergeMoveMenuComponent } from '../merge-move-menu/merge-move-menu.component';
import { FontService } from './../../services/font-manager.service';
import { checkFontSource } from '../fonts.utils';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, PreventClickDirective, MergeMoveMenuComponent],
    selector: 'font-family',
    templateUrl: './font-family.component.html',
    styleUrls: ['./font-family.component.scss']
})
export class FontFamilyComponent implements OnInit {
    private authService = inject(AuthService);
    private fontService = inject(FontService);

    fontFamily = input.required<FontFamily>();
    isSelectedFontFamily = input.required<boolean>();

    openSettings = output<FontFamily>();
    selectFontFamily = output<FontFamily>();
    confirmMergeFontFamily = output<{
        fontFamily: FontFamily;
        mergeTo: FontFamily;
    }>();

    isAdmin = this.authService.isAdmin;

    fontSource: 'upload' | 'googleFonts' | 'mixed';

    ngOnInit(): void {
        this.fontSource = checkFontSource(this.fontFamily().fontStyles);
    }

    isAllowedToModifyFont = this.fontService.isAllowedToModifyFont;

    select(): void {
        const fontFamily = this.fontFamily();
        if (!this.isSelectedFontFamily()) {
            this.selectFontFamily.emit(fontFamily);
        }
    }
}
