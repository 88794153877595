import { Component, ElementRef, ViewChild, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import { FontService } from '../../services/font-manager.service';
import { FontUploadService } from '../../services/font-upload.service';
import { FontFamily } from '../../models/fontFamily.model';
import { DragDirective } from '../../directive/drag-drop.directive';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, DragDirective],
    selector: 'font-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
    private fontService = inject(FontService);
    private fontUploadService = inject(FontUploadService);
    private uiNotificationService = inject(UINotificationService);

    @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;
    @Input() selectedFontFamily: FontFamily;
    @Input() hasDragDrop = false;

    private hasError = false;

    async uploadFiles(files: File[]): Promise<void> {
        const success = await this.fontUploadService.uploadFiles(files, this.selectedFontFamily);

        if (success) {
            this.displaySuccess();
        } else {
            this.displayError();
        }
    }

    isAllowedToModifyFont(selectedFontFamily: FontFamily): boolean {
        return this.fontService.isAllowedToModifyFont(selectedFontFamily);
    }

    private displaySuccess(): void {
        if (!this.hasError && !this.fontService.hasError) {
            this.uiNotificationService.open('Font uploaded successfully!', {
                type: 'success',
                placement: 'top',
                autoCloseDelay: 3000
            });
        }
    }

    private displayError(errorText?: string): void {
        this.hasError = true;
        this.fileUpload.nativeElement.value = '';

        this.uiNotificationService.open(
            errorText ?? 'Something went wrong when uploading the file. Please try again.',
            {
                type: 'error',
                placement: 'top',
                autoCloseDelay: 3000
            }
        );
    }
}
