import { ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    inject,
    input,
    output,
    viewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    UIAlertDialogService,
    UIConfirmDialogResult,
    UIConfirmDialogService,
    UIInputComponent,
    UIModule,
    UINotificationService
} from '@bannerflow/ui';
import * as opentype from 'opentype.js';
import { SelectionService } from 'src/services/font-selection.service';
import { FontFamily, FontStyle } from '../../models/fontFamily.model';
import { FontService } from '../../services/font-manager.service';
import { MergeMoveMenuComponent } from '../merge-move-menu/merge-move-menu.component';
import { FontLoaderService } from './font-loader.service';
import { FontPreviewComponent } from '../font-preview/font-preview.component';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, MergeMoveMenuComponent, FontPreviewComponent],
    selector: 'font-style',
    templateUrl: './font-style.component.html',
    styleUrls: ['./font-style.component.scss'],
    host: {
        '(document:click)': 'handleDocumentClick($event)'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontStyleComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private elementRef = inject(ElementRef);
    private fontLoaderService = inject(FontLoaderService);
    private fontService = inject(FontService);
    private selectionService = inject(SelectionService);
    private uiAlertDialogService = inject(UIAlertDialogService);
    private uiConfirmDialogService = inject(UIConfirmDialogService);
    private uiNotificationService = inject(UINotificationService);

    @Input() fontStyle: FontStyle;
    // fontStyle = input.required<FontStyle>();
    customText = input.required<string>();
    fontFamily = input.required<FontFamily>();
    displayType = input<string>('compact');

    private fontStyleNameInput = viewChild<UIInputComponent>('fontStyleNameInput');
    moveMenu = viewChild<ElementRef<MergeMoveMenuComponent>>('moveMenu');

    deleteFontStyle = output<FontStyle>();

    showAllGlyphs: boolean;
    allGlyphString = '';
    isStyleSelected: boolean;
    fontStyleMetaData: opentype.Font;
    newName: string;
    fontFamilyName = '';
    showRenameInput = false;

    leftAlignedDropDownPositions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center'
        }
    ];

    hasLoaded = false;

    private showingRenameInput = false;

    isAllowedToModifyFont(fontFamily: FontFamily): boolean {
        return this.fontService.isAllowedToModifyFont(fontFamily);
    }

    get fontStyles(): FontStyle[] {
        return this.selectionService.fontStyles;
    }

    async ngOnInit(): Promise<void> {
        await this.fontLoaderService.loadStyle(this.fontFamily(), this.fontStyle);
        this.subscribeToFontStyleSelection();
    }

    constructor() {
        this.destroyRef.onDestroy(() => {
            const fontFamily = this.fontFamily();
            const fontStyle = this.fontStyle;
            if (fontFamily && fontStyle) {
                this.fontLoaderService.removeStyle(
                    this.fontService.getFontStyleName(fontFamily, fontStyle)
                );
            }
        });
    }

    private subscribeToFontStyleSelection(): void {
        this.selectionService.fontStyleUpdate.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
            this.isStyleSelected = this.selectionService.isStyleSelected(this.fontStyle);
        });
    }

    selectionChanged(): void {
        this.selectionService.toggleSelection(this.fontStyle, !this.isStyleSelected);
    }

    async confirmDeletion(): Promise<void> {
        const result: UIConfirmDialogResult = await this.uiConfirmDialogService.confirm({
            headerText: 'Are you sure?',
            text: `Do you really want to delete this font style?
            <br/><br/>Please note that this will have no effect on existing creatives using this font, but the font will no longer be available in Creative Studio.`,
            confirmText: 'Delete style'
        });

        if (result === 'confirm') {
            try {
                this.doDeleteFontStyle();
            } catch (err) {
                return;
            }
        }
    }

    private async doDeleteFontStyle(): Promise<void> {
        const fontFamily = this.fontFamily();
        const fontStyle = this.fontStyle;
        const stylesToDelete: FontStyle[] = [
            ...this.selectionService.fontStyles.filter(someStyle => someStyle.id !== fontStyle.id),
            fontStyle
        ];

        await this.fontService.deleteFontStyles(fontFamily, stylesToDelete);

        for (const style of stylesToDelete) {
            const index = fontFamily.fontStyles.indexOf(style);
            if (index !== -1) {
                fontFamily.fontStyles.splice(index, 1);
            }
        }

        this.uiNotificationService.open('Font style deleted successfully!', {
            type: 'success',
            placement: 'top',
            autoCloseDelay: 3000
        });
        this.deleteFontStyle.emit(fontStyle);
        this.selectionService.fontStyles = [];
    }

    openRenameInput(): void {
        this.showRenameInput = !this.showRenameInput;

        if (this.showRenameInput) {
            this.newName = this.fontStyle.name;

            // Set focus on the newly added Input
            setTimeout(() => {
                this.fontStyleNameInput()?.valueContainer.nativeElement.focus();
                this.showingRenameInput = true;
            });
        }
    }

    toggleKebabMenu(): void {
        this.closeRenameInput();
        if (!this.isStyleSelected) {
            this.selectionService.fontStyles = [];
        }
    }

    closeRenameInput(): void {
        this.showRenameInput = false;
        this.showingRenameInput = false;
    }

    async renameFontStyle(): Promise<void> {
        const fontFamily = this.fontFamily();
        const fontStyle = this.fontStyle;
        await this.fontService.renameFontStyle(fontFamily, fontStyle, this.newName);

        this.showRenameInput = false;
        this.showingRenameInput = false;
        fontStyle.name = this.newName;
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent): void {
        if (this.showingRenameInput) {
            if (event.key === 'Enter') {
                // If enter
                if (this.fontStyle.name === this.newName) {
                    this.showRenameInput = false;
                    this.showingRenameInput = false;
                } else {
                    if (!this.newName) {
                        this.showRenameInput = false;
                        this.showingRenameInput = false;

                        this.uiAlertDialogService.show({
                            headerText: 'An error has occured',
                            text: 'Name cannot be empty',
                            confirmText: 'Ok'
                        });
                    } else {
                        this.renameFontStyle();
                    }
                }
            } else if (event.key === 'Escape') {
                // If escape
                this.closeRenameInput();
            }
        }
    }

    /*
     * Function to close the location picker if you click outside of it.
     */
    handleDocumentClick(event: MouseEvent): void {
        if (this.showRenameInput && this.showingRenameInput) {
            let clickedComponent: EventTarget | null = event.target;
            if (clickedComponent) {
                let inside = false;

                // Make sure it's only closed if the user actually clicks outside of the component.
                do {
                    if (clickedComponent === this.elementRef.nativeElement) {
                        inside = true;
                        break;
                    } else {
                        inside = false;
                    }

                    clickedComponent = (clickedComponent as HTMLElement).parentNode;
                } while (clickedComponent);

                if (!inside) {
                    this.closeRenameInput();
                }
            }
        }
    }

    downloadStyle(): void {
        if (this.selectionService.fontStyles.length > 1) {
            this.fontService.downloadFonts(this.selectionService.fontStyles);
        } else {
            this.fontService.downloadFont(this.fontStyle);
        }
    }

    async confirmMoveFontStyle(mergeTo: FontFamily): Promise<void> {
        let fontStylesToMove: FontStyle[] = [];

        if (!this.selectionService.fontStyles.length) {
            fontStylesToMove.push(this.fontStyle);
        } else {
            fontStylesToMove = this.selectionService.fontStyles;
        }

        const answer = await this.uiConfirmDialogService.confirm({
            headerText: 'Move to family',
            text: `Move ${fontStylesToMove.length} font ${fontStylesToMove.length === 1 ? 'style' : 'styles'} to ${mergeTo.name}?`,
            confirmText: `Move style${fontStylesToMove.length === 1 ? '' : `s (${fontStylesToMove.length})`}`
        });

        if (answer === 'confirm') {
            this.moveFontStyle(mergeTo, fontStylesToMove);
        }
    }

    private async moveFontStyle(mergeTo: FontFamily, fontStylesToMove: FontStyle[]): Promise<void> {
        const fontFamily = this.fontFamily();
        if (!fontFamily) {
            return;
        }
        const updatedMergeToFamily: FontFamily | null = await this.fontService.moveFontStyles(
            fontFamily,
            mergeTo,
            fontStylesToMove
        );

        // Make sure that our frontend is updated with the correct information
        if (updatedMergeToFamily) {
            const hasSourceFamilyZeroStylesAfterMove =
                fontStylesToMove.length - fontFamily.fontStyles.length === 0;
            fontFamily.fontStyles = fontFamily.fontStyles.filter(
                style => !fontStylesToMove.includes(style)
            );

            const updatedFontFamilies: FontFamily[] = [
                ...(this.fontService.fontFamilies = this.fontService.fontFamilies.filter(
                    family => family.id !== mergeTo.id && family.id !== fontFamily.id
                )),
                updatedMergeToFamily
            ];

            if (!hasSourceFamilyZeroStylesAfterMove) {
                updatedFontFamilies.push(fontFamily);
            }

            this.fontService.fontFamilies = updatedFontFamilies;
        }
    }
}
