import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoaderComponent } from '../font-style/lazy-loader.component';
import { FontService } from 'src/services';
import { checkFontSource } from '../fonts.utils';
import {
    FontFamilyItemComponent,
    FontFamilyWithSource
} from '../font-family-item/font-family-item.component.';
import { FontFamily } from 'src/models';

@Component({
    standalone: true,
    imports: [CommonModule, LazyLoaderComponent, FontFamilyItemComponent],
    selector: 'font-families',
    templateUrl: './font-families.component.html',
    styleUrls: ['./font-families.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontFamiliesComponent {
    private fontService = inject(FontService);
    customText = input<string>();
    displayType = input<string>();

    confirmMergeFontFamily = output<{ fontFamily: FontFamily; mergeTo: FontFamily }>();
    openSettings = output<FontFamily>();

    fontFamilies: FontFamilyWithSource[];

    constructor() {
        this.fontFamilies = this.fontService.fontFamilies
            .map(fontFamily => {
                return {
                    ...fontFamily,
                    source: checkFontSource(fontFamily.fontStyles)
                };
            })
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    mergeFontFamily(event: { fontFamily: FontFamily; mergeTo: FontFamily }): void {
        this.confirmMergeFontFamily.emit(event);
    }

    settings(event: FontFamily): void {
        this.openSettings.emit(event);
    }
}
