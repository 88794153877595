import { Component, inject, input, Input, output } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { FontFamily, FontStyle } from 'src/models';
import { FontService } from 'src/services';
import { SelectionService } from 'src/services/font-selection.service';
import { FontStyleHeaderComponent } from '../../font-style-header/font-style-header.component';
import { UploadComponent } from '../../upload/upload.component';
import { MergeMoveMenuComponent } from '../../merge-move-menu/merge-move-menu.component';

@Component({
    standalone: true,
    imports: [UIModule, FontStyleHeaderComponent, UploadComponent, MergeMoveMenuComponent],
    selector: 'font-manager-topbar',
    templateUrl: './font-manager-topbar.component.html',
    styleUrls: ['./font-manager-topbar.component.scss']
})
export class FontManagerTopbarComponent {
    private fontService = inject(FontService);
    private selectionService = inject(SelectionService);

    // Cannot be a signal since it's being updated in template
    // TODO: Refactor
    @Input() selectedFontFamily: FontFamily;
    isLoading = input<boolean>();
    customText = input.required<string>();
    showAllFontFamilies = input.required<boolean>();

    customTextChange = output<string>();
    changeDisplayType = output<string>();
    confirmStylesDeletion = output<void>();
    mergeToFontFamily = output<FontFamily>();
    toggleSettings = output<void>();

    get fontFamilies(): FontFamily[] {
        return this.fontService.fontFamilies;
    }

    get fontStyles(): FontStyle[] {
        return this.selectionService.fontStyles;
    }

    isAllowedToModifyFont(fontFamily: FontFamily): boolean {
        return this.fontService.isAllowedToModifyFont(fontFamily);
    }

    onCustomTextChange(event: string): void {
        this.customTextChange.emit(event);
    }

    onChangeDisplayType(event: string): void {
        this.changeDisplayType.emit(event);
    }

    onConfirmStylesDeletion(): void {
        this.confirmStylesDeletion.emit();
    }

    onToggleSettings(): void {
        this.toggleSettings.emit();
    }

    mergeFontFamily(fontFamily: FontFamily): void {
        this.mergeToFontFamily.emit(fontFamily);
    }
}
