import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { AuthService, GenericError } from '@auth0/auth0-angular';
import { UIModule, UIThemeService } from '@bannerflow/ui';
import { filter, mergeMap } from 'rxjs';
import { FontService } from 'src/services';
import { GoogleTagManagerService } from 'src/services/google-tag-manager.service';
import { TrackerService } from 'src/services/tracker.service';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, RouterOutlet],
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    private authService = inject(AuthService);
    private fontService = inject(FontService);
    private googleTagManagerService = inject(GoogleTagManagerService); // Make sure GTM is initialized
    private trackerService = inject(TrackerService);

    get loading(): boolean {
        return this.fontService.isLoading;
    }

    constructor() {
        inject(UIThemeService); // To make sure that the themes are read from the URL, we need to import themeService somewhere. No need for any actions though.
        this.trackerService.initNewRelic();

        this.authService.error$
            .pipe(
                takeUntilDestroyed(),
                filter(
                    e =>
                        e instanceof GenericError &&
                        (e.error === 'login_required' || e.error === 'invalid_grant')
                ),
                mergeMap(() => this.authService.logout())
            )
            .subscribe();

        this.googleTagManagerService.initializeGtm();
    }
}
